<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人员管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">日报人员列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入绑定人员姓名"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" />
              <el-table-column label="姓名" align="left" prop="userName" show-overflow-tooltip 
              width="180"/>
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                width="180"
              />
              <el-table-column
                label="订阅班级"
                align="left"
                prop="projectNameList"
                show-overflow-tooltip
              />
              <el-table-column label="操作" align="center" width="100px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    @click="handleEdit(scope.row.compId,scope.row.wechatUserId,scope.row.userName)"
                  >解绑</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "classDaily",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      userName: "",
      mobile: ""
    };
  },
  computed: {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userName: this.userName || "",
        mobile: this.mobile || ""
      };
      this.doFetch({
        url: "/biz/subscribeProject/querySubscribeProjectList",
        params,
        pageNum
      });
    },
    // 解绑人员
    handleEdit(compId,wechatUserId,userName) {
      this.$confirm('<div style="text-align: center;font-size: 18px; margin-bottom: 10px;">确定解绑吗？</div><div style="text-align: center;">解绑后，该学员不再接受日报推送！</div>', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        dangerouslyUseHTMLString: true,
      }).then(() => {
        this.$post("/biz/subscribeProject/deleteSubscribeProject", {
        compId: compId,
        wechatUserId
        }).then(re => {
          if (re.status == "0") {
            this.$message({
            message: userName + "解绑成功",
            type: "success"
          });
            this.getData(-1)
          } else {
              this.getData(-1)
          }
        });
      
      }).catch(()=>{

      });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less" scoped>
</style>
